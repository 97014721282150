<template>
  <div id="box">
    <div class="user-photo">
      <uploadPhoto
        class="avatar-uploader"
        :is-self="isSelf"
        :user-id="userId"
        ref="uploadPhotoRef"
      ></uploadPhoto>
    </div>
    <div class="user-name">
      <p>{{ userData.fullName }}</p>
    </div>
    <a-row class="info-part">
      <a-col :span="8">
        <span class="label">员工编号：</span>
        <span class="info">{{ userData?.idNumber ? userData.idNumber : "-" }}</span>
      </a-col>
      <a-col :span="8">
        <span class="label">直属上级：</span>
        <span class="info">{{ userData?.lmName ? userData.lmName : "-" }}</span>
      </a-col>
      <a-col :span="8">
        <span class="label">人员状态：</span>
        <span class="info">{{ userData?.status ? userData.status : "-" }}</span>
      </a-col>
      <a-col :span="8">
        <span class="label">GGID：</span>
        <span class="info">{{ userData?.ggId ? userData.ggId : "-" }}</span>
      </a-col>
      <a-col :span="8">
        <span class="label">职级：</span>
        <span class="info">{{ userData?.psGroup ? userData.psGroup : "-" }}</span>
      </a-col>
      <a-col :span="8">
        <span class="label">工作地点：</span>
        <span class="info">{{ userData?.workLocation ? userData.workLocation : "-" }}</span>
      </a-col>
      <a-col :span="8">
        <span class="label">手机号码：</span>
        <span class="info">{{ userData?.phoneNo ? userData.phoneNo : "-" }}</span>
      </a-col>
      <a-col :span="8">
        <span class="label">团队：</span>
        <span class="info">{{ userData?.orgThreeTwo ? userData.orgThreeTwo : "-" }}</span>
      </a-col>
      <a-col :span="8">
        <span class="label">邮箱：</span>
        <span class="info">{{ userData?.email ? userData.email : "-" }}</span>
      </a-col>
      <a-col :span="8">
        <span class="label">入职时间：</span>
        <span class="info">{{ userData?.onboardDate ? userData.onboardDate : "-" }}</span>
      </a-col>
      <a-col :span="8">
        <span class="label">子团队：</span>
        <span class="info">{{ userData?.orgFourOneOne ? userData.orgFourOneOne : "-" }}</span>
      </a-col>
      <a-col :span="8" v-if="!isShowRole">
        <span class="label">角色：</span>
        <span class="info">{{ userData?.position ? userData.position : "-" }}</span>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { defineComponent, onMounted,ref } from "vue";
import uploadPhoto from "@/components/uploadPhoto.vue";

export default defineComponent({
  components: {
    uploadPhoto,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
    isSelf: Boolean,
    userId: String,
    isShowRole: Boolean,
  },
  setup() {
    onMounted(() => {});
    const getPhoto1 = () => {
      uploadPhotoRef.value.getPhotoUrl()
    }
    const uploadPhotoRef = ref()
    return {
      getPhoto1,
      uploadPhotoRef
    };
  },
});
</script>
<style lang="less" scoped>
#box {
  position: relative;
  margin-top: -80px;

  .user-photo {
    width: 100%;
    display: flex;
    justify-content: center;

    .avatar-uploader {
      width: 100px;
      height: 100px;
    }
  }

  .user-name {
    text-align: center;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #2d3748;
  }

  .info-part {
    margin-top: 33px;

    .label {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 34px;
      color: #2d3748;
      padding-right: 10px;
    }

    .info {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #12abdb;
    }
  }
}
</style>
