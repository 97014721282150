<template>
  <div id="ctf">
    <div class="table" ref="tableRef">
      <a-table
        :data-source="workExperienceList"
        size="small"
        :columns="workExperienceCol"
        :rowKey="(record) => record.id"
        class="gray-table"
        :rowClassName="
          (record, index) => (index / 1 === index ? 'table-striped' : null)
        "
        :pagination="pagination"
        :scroll="{ x: tableWidth }"
      >
      </a-table>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  ref,
  computed,
  nextTick,
  onMounted,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useStore } from "vuex";

export default defineComponent({
  name: "WorkExperience",
  props: {
    isSelf: Boolean,
    showWorkExperienceCol: Boolean,
    userId: String,
  },
  setup(props) {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const isPM = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("3") !== -1
    })
    const workExperienceCol = computed(() => {
      if (isPM.value) {
    return [
      {
        title: "项目名称",
        dataIndex: "engagementDescription",
        ellipsis: true,
        width: 250,
      },
      {
        title: "客户名称",
        dataIndex: "account",
        ellipsis: true,
        width: 200,
      },
      {
        title: "上项目时间",
        dataIndex: "onBoardDate",
        ellipsis: true,
        width: 100,
      },
      {
        title: "下项目时间",
        dataIndex: "actualReleaseDate",
        ellipsis: true,
        width: 100,
      },
    ];
  }
      if (
        (store.state.app.perms.indexOf("system:workexperience") !== -1 ||
          props.showWorkExperienceCol) &&
        !props.isSelf
      ) {
        return [
          {
            title: "项目名称",
            dataIndex: "engagementDescription",
            ellipsis: true,
            width: 250,
          },
          {
            title: "客户名称",
            dataIndex: "account",
            ellipsis: true,
            width: 200,
          },
          {
            title: "上项目时间",
            dataIndex: "onBoardDate",
            ellipsis: true,
            width: 200,
          },
          {
            title: "下项目时间",
            dataIndex: "actualReleaseDate",
            ellipsis: true,
            width: 200,
          },
          {
            title: "态度",
            dataIndex: "attitude",
            ellipsis: true,
            width: 100,
          },
          {
            title: "技能",
            dataIndex: "skill",
            ellipsis: true,
            width: 100,
          },
          {
            title: "英语（可选）",
            dataIndex: "english",
            ellipsis: true,
            width: 150,
          },
          {
            title: "团队合作",
            dataIndex: "teamwork",
            ellipsis: true,
            width: 100,
          },
          {
            title: "综合得分",
            dataIndex: "syntheticalScore",
            ellipsis: true,
            width: 150,
          },
          {
            title: "评价时间",
            dataIndex: "reviewDate",
            ellipsis: true,
            width: 200,
          },
          {
            title: "项目中的关键人才",
            dataIndex: "keyTalent",
            ellipsis: true,
            width: 150,
          },
          {
            title: "离职风险",
            dataIndex: "retentionRisk",
            ellipsis: true,
            width: 100,
          },
          {
            title: "总结",
            dataIndex: "summary",
            ellipsis: true,
            width: 300,
          },
        ];
      }
      return [
        {
          title: "项目名称",
          dataIndex: "engagementDescription",
          ellipsis: true,
          width: 250,
        },
        {
          title: "客户名称",
          dataIndex: "account",
          ellipsis: true,
          width: 200,
        },
        {
          title: "上项目时间",
          dataIndex: "onBoardDate",
          ellipsis: true,
          width: 100,
        },
        {
          title: "下项目时间",
          dataIndex: "actualReleaseDate",
          ellipsis: true,
          width: 100,
        },
      ];
    });
    const workExperienceList = ref([]);
    const getWorkExperienceList = () => {
      const params = {
        userId: props.userId,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      $api.getWorkExperience(params).then((res) => {
        workExperienceList.value = res.data.resource;
        pagination.total = res.data.total;
      });
    };
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: false,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getWorkExperienceList();
      },
    });
    getWorkExperienceList();

    const tableWidth = ref(0);
    const tableRef = ref();

    const getSize = () => {
      nextTick(() => {
        tableWidth.value = tableRef.value.offsetWidth;
      });
    };

    onMounted(() => {
      getSize();
    });

    return {
      workExperienceCol,
      workExperienceList,
      pagination,
      tableWidth,
      tableRef,
    };
  },
});
</script>
<style lang="less" scoped>
.ant-modal-header {
  height: 80px;
  line-height: 80px;
}

#ctf {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  //width: 1850px;
  margin-left: 15px;

  .table {
    margin-top: 30px;
    .edit {
      width: 20px;
      cursor: pointer;
    }
  }
}
</style>
