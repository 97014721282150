<template>
  <div class="staffing-skill">
    <span class="red-text" v-show="hasEmptySkill">技能评分不能为空</span>
    <div class="update-button" v-if="isSelf">
      <a-space v-if="!delBtnShow">
        <a-button class="edit-button blue-border-btn" @click="editClick" v-auth="['system:userInfo:staffingSkill:edit']">
          编辑
        </a-button>

        <a-button class="edit-button blue-btn" @click="showModal" v-auth="['system:userInfo:staffingSkill:add']">
          新增
        </a-button>
      </a-space>
      <a-space v-else>
        <a-button
          class="edit-button blue-border-btn"
          @click="getAllStaffSkillStar"
          v-auth="['system:userInfo:staffingSkill:close']"
        >
          取消
        </a-button>

        <a-button class="edit-button blue-btn" @click="saveClick" v-auth="['system:userInfo:staffingSkill:close']">
          保存
        </a-button>
      </a-space>
    </div>
    <div>
      <div
        v-for="(item, index) in starList"
        :key="index"
        :class="[
          'star-list',
          { 'blue-tag': item.name == skillDept },
          { 'hover-bg': item.name == skillDept && !delBtnShow },
        ]"
      >
        <div class="category-name">{{ item.name }}</div>
        <div class="line"></div>
        <div class="skills">
          <div
            v-for="(value, i) in item.list"
            :key="value.skillId"
            :class="['skill-name', delBtnShow ? 'skill-name-hover' : '']"
          >
            <span class="text" :title="value.skillName">{{ value.skillName }}</span>
            <a-rate
              v-model:value="value.skillStar"
              class="icon"
              :disabled="!isSelf || !delBtnShow"

            />
            <DeleteOutlined
              @click="delskill(index, i)"
              title="delete"
              :class="['del-button', delBtnShow ? 'del-button-hover' : '']"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="staffing-modal" ref="staffingModal">
      <a-drawer
        :getContainer="() => $refs.staffingModal"
        class="staffing-modal"
        v-model:visible="visible"
        title="技能新增"
        :width="'50vw'"
        @cancel="closeModal"
      >
        <!--        <PerfectScrollbar>-->
        <div class="dialog">
          <!--            <PerfectScrollbar>-->
          <div class="skillBox">
            <div class="box">
              <div
                :class="['title', skillDept ? 'dept-skill' : '', selectCategory === item ? 'actives' : '']"
                v-for="item in categoryList"
                :key="item"
              >
                <div class="menu" @click="categoryClick(item)" :title="item">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <!--            <PerfectScrollbar>-->
          <!--            <PerfectScrollbar>-->
          <div class="title-child">
            <a-checkbox-group v-model:value="selectSkillId[selectCategory]">
              <a-checkbox
                class="title-type"
                :value="value.skillId"
                v-for="value in skillList"
                :key="value.skillId"
              >
                <div class="categories" :title="value.skillName">
                  {{ value.skillName }}
                </div>
              </a-checkbox>
            </a-checkbox-group>
          </div>
          <!--            </PerfectScrollbar>-->
        </div>
        <!--        </PerfectScrollbar>-->
        <a-space class="button bottom-btn">
          <a-button type="primary" ghost @click="cancelClick">取消</a-button>
          <a-button type="primary" @click="submitClick">确定 </a-button>
        </a-space>
      </a-drawer>
    </div>
  </div>
</template>
<script>
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { defineComponent, reactive, ref, toRefs, onMounted, watch,computed } from "vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
export default defineComponent({
  name: "Home",
  components: {
    DeleteOutlined,
  },
  props: {
    skillDept: {
      type: String,
    },
    isSelf: Boolean,
    userId: String,
  },
  // emits: ['skill-star-change'],
  setup(props) {
    const { $api } = useGlobalPropertyHook();
    let state = reactive({
      categoryList: [],
      skillList: [],
      starList: [],
      selectSkillId: {},
      selectSkill: {},
    });
    //递归判断打分项是否为空
    function hasZeroSkillStar(data) {
      if (Array.isArray(data)) {
        // 如果 data 是数组，则遍历每个元素并递归调用 hasZeroSkillStar
        for (const item of data) {
          if (hasZeroSkillStar(item)) {
            // 如果有任何一个元素返回 true，则直接返回 true
            return true;
          }
        }
      } else if (typeof data === "object") {
        // 如果 data 是对象
        if (data.list) {
          // 如果对象有 list 属性，则递归调用 hasZeroSkillStar
          if (hasZeroSkillStar(data.list)) {
            return true;
          }
        } else if (!data.skillStar) {
          // 如果对象没有 list 属性，并且 skillStar 为 0，则返回 true
          return true;
        }
        // 需要判断如果既没有list也没有skillstar
      }
      return false;
    }
    //判断打分项是否为空
    const hasEmptySkill = computed(()=>hasZeroSkillStar(state.starList));
    let selectCategory = ref();
    const delBtnShow = ref(false);
    // const saveSkillDept = toRefs(props.skillDept);
    let categoryClick = (item) => {
      setSelectSkill(selectCategory.value);
      selectCategory.value = item;
      console.log(selectCategory.value);
      getSkillList(item);
    };

    watch(selectCategory, () => {
      const dom = document.getElementsByClassName("title-child")[0];
      dom && dom.scrollTo(0, 0);
    });
    const visible = ref(false);
    let getCategoryList = () => {
      const params = {
        skillDept: props.skillDept,
      };
      return $api.getStaffingSkillCategory(params).then((res) => {
        state.categoryList = res.data;
        const index = state.categoryList.indexOf(props.skillDept);
        if (index !== -1 && index !== 0) {
          state.categoryList.splice(index, 1);
          state.categoryList.unshift(props.skillDept);
        }
        if (res.data.length > 0) {
          selectCategory.value = res.data[0];
          getSkillList(res.data[0]);
        }
      });
    };

    let getSkillList = (category) => {
      const params = {
        category,
        skillDept: props.skillDept,
      };
      return $api
        .getSkillList(params)
        .then((res) => {
          state.skillList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const setSelectSkill = (selectCategory) => {
      state.selectSkill[selectCategory] = [];
      const skillIdList = state.selectSkillId[selectCategory];
      if (skillIdList) {
        for (let i = 0; i < skillIdList.length; i++) {
          const skill = state.skillList.find(
            (item) => item.skillId === skillIdList[i]
          );
          state.selectSkill[selectCategory].push(skill);
        }
      }
    };

    const group = (arr, key) => {
      const unique = (arr) => {
        const res = new Map();
        return arr.filter((a) => !res.has(a) && res.set(a, 1));
      };
      let allGroupName = arr.map((item) => {
        return item[key];
      });
      let typeList = unique(allGroupName);
      let list = [];
      typeList.forEach((ele) => {
        let obj = {};
        obj.list = [];
        obj.list = arr.filter((sele) => ele == sele[key]);

        obj.name = ele;
        list.push(obj);
      });
      console.log(1,list)
      return list;
    };

    const closeModal = () => {
      visible.value = false;
      state.categoryList = [];
      state.skillList = [];
      state.selectSkillId = {};
      state.selectSkill = {};
    };

    const sortStar = () => {
      state.starList.sort((a, b) => {
        if (a.name === props.skillDept) {
          return -1;
        }
        if (b.name === props.skillDept) {
          return 1;
        }
        let aName = a.name.toLowerCase();
        let bName = b.name.toLowerCase();
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      });
      state.starList.forEach((item) => {
        item.list.sort((a, b) => {
          // 先按照 skillStar 降序排序
          if (b.skillStar !== a.skillStar) {
            return b.skillStar - a.skillStar;
          } else {
            // 如果 skillStar 相同，则按照 skillName 首字母排序（忽略大小写）
            let aName = a.skillName.toLowerCase();
            let bName = b.skillName.toLowerCase();
            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
          }
        });
      });
    };

    const setShowStar = () => {
      const starList = state.starList;
      state.starList = [];
      Object.keys(state.selectSkill).forEach((category) => {
        if (state.selectSkill[category].length === 0) return;
        const starCategory = starList.find((item) => item.name === category);
        if (starCategory && starCategory.list) {
          const skillList = [];
          state.selectSkill[category].forEach((skill) => {
            const star = starCategory.list.find(
              (item) => item.skillId === skill.skillId
            );
            if (star) {
              skillList.push(star);
            } else {
              skillList.push(skill);
            }
          });
          state.starList.push({
            name: category,
            list: skillList,
          });
        } else {
          state.starList.push({
            name: category,
            list: state.selectSkill[category],
          });
        }
        sortStar();
      });
    };

    let submitClick = () => {
      delBtnShow.value = true;
      setSelectSkill(selectCategory.value);
      setShowStar();
      closeModal();
    };

    const cancelClick = () => {
      visible.value = false;
    };

    const setSelectSkillId = () => {
      for (let i = 0; i < state.starList.length; i++) {
        state.selectSkillId[state.starList[i].name] = state.starList[
          i
        ].list.map((item) => item.skillId);
      }
    };

    const initSelectSkill = () => {
      Object.keys(state.selectSkillId).forEach((category) => {
        state.selectSkill[category] = [];
        const skillList = state.selectSkillId[category];
        if (skillList) {
          for (let i = 0; i < skillList.length; i++) {
            const starList = state.starList.find(
              (item) => item.name === category
            );
            if (starList && starList.list) {
              const skill = starList.list.find(
                (item) => item.skillId === skillList[i]
              );
              state.selectSkill[category].push(skill);
            }
          }
        }
      });
    };

    const showModal = () => {
      visible.value = true;
      setSelectSkillId();
      initSelectSkill();
      getCategoryList();
    };

    const editClick = () => {
      delBtnShow.value = true;
    };

    let getAllStaffSkillStar = () => {
      delBtnShow.value = false;
      const params = {
        userId: props.userId,
      };
      $api.getStaffSkill(params).then((res) => {
        
        state.starList = group(res.data, "skillCategroyName");
        sortStar();
        console.log("state.list",state.starList);
      });
    };

    async function getData() {
      await getAllStaffSkillStar();
    }

    let delskill = (categoryIndex, skillIndex) => {
      if (state.starList[categoryIndex].list.length === 1) {
        state.starList.splice(categoryIndex, 1);
      } else {
        state.starList[categoryIndex].list.splice(skillIndex, 1);
      }
    };

    const saveClick = () => {
      if (delBtnShow.value) {
    const isEmpty = state.starList.some(category => category.list.some(skill => !skill.skillStar));
    if (isEmpty) {
      message.error("请确保所有技能都有评分后再保存。");
      return;
    }

  }
  const skillList = [];
  state.starList.forEach(category => {
    skillList.push(...category.list);
  });
  $api.updateStaffSkill(skillList).then((res) => {
    message.success(`${res.msg} 已更新技能信息.`);
    getData();
  });
    };

    onMounted(async () => {
      getAllStaffSkillStar();
    });

    return {
      selectCategory,
      categoryClick,
      delskill,
      showModal,
      submitClick,
      visible,
      ...toRefs(state),
      getAllStaffSkillStar,
      saveClick,
      closeModal,
      editClick,
      delBtnShow,
      cancelClick,
      hasEmptySkill
      // saveSkillDept
    };
  },
});
</script>
<style scoped lang="less">
.staffing-skill {
  margin-top: -30px;
  position: relative;
  padding-top: 60px;
  .red-text{
    color: red;
    font-size: 12px;
    position: absolute;
    top: 5px;
    left: 100px;
  }
  .update-button {
    position: absolute;
    right: 0;
    top: 0;

    .edit-button {
      width: 84px;
    }
  }

  .star-list {
    display: flex;
    margin-right: -25px;
    &.hover-bg {
      background: #fafafa;
    }
    &.blue-tag {
      &::before {
        content: "团队技能";
        width: 25px;
        background: #95bce0;
        color: white;
        display: flex;
        align-items: center;
        /* justify-content: center; */
        text-align: center;
        margin-left: -25px;
      }
    }

    .category-name {
      width: 300px;
      white-space: nowrap;
      //overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #2d3748;
    }

    .line {
      width: 1px;
      background: #e0e1e2;
      margin: 0 20px 0 10px;
    }

    .skills {
      flex: 1;
      display: flex;
      flex-wrap: wrap; //让弹性盒元素在必要的时候拆行
      //width: 1895px;
      justify-content: space-between;

      .skill-name {
        //width: 620px;
        min-width: calc(100% / 2 - 10px);
        height: 54px;
        padding: 0 10px;
        position: relative;
        line-height: 54px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #505050;
        margin-right: 10px;
        margin-bottom: 8px;
        vertical-align: middle;
        .text{
              max-width: 28vw;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              float: left;
        }

        &.skill-name-hover {
          background: #f7fafc;
        }

        .icon {
          padding-left: 10px;
          color: #12abdb;
          margin-right:1.5vw;
        }

        .del-button {
          display: none;
          font-size: 20px;
          color: #999;
          position: absolute;
          right: 10px;
          height: 20px;
          top: calc(50% - 10px);
        }

        .del-button-hover {
          display: block;
        }
      }
    }
  }
.staffing-modal{
  .button{
    display: flex;
    justify-content: flex-end;
  }
}
  .star-list + .star-list {
    margin-top: 40px;
  }
}

.submitButton {
  width: 99px;
  height: 35px;
  background: #1890ff;
  border-radius: 4px;
}

:deep(.ant-modal-header) {
  height: 80px;
  line-height: 80px;
}

.dialog {
  display: flex;
  // height: 100%;
}

.skillBox {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

.box {
  // height: 100%;
  position: relative;
  // max-height: 400px;

  .title {
    word-wrap: break-word;
    width: 200px;
    height: 80px;
    color: #999;
    text-align: center;
    position: relative;

    &.dept-skill:nth-of-type(1) {
      &::before {
        content: "团队技能";
        width: 20px;
        background: #95bce0;
        color: white;
        display: flex;
        align-items: center;
        /* justify-content: center; */
        text-align: center;
        margin-right: 5px;
      }
    }

    .menu {
      margin-top: 5px;
      font-size: 20px;
      line-height: 23px;
      width: 120px;
      text-align: center;
      // height: 70px;
      // max-height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .actives {
    color: #3a99f2;

    .menu {
      text-decoration: underline;
    }
  }
}

.title-child {
  left: 100px;
  display: block;
  // height: 400px;
  // max-height: 400px;
  background-color: white;
  padding-top: 15px;
  padding: 5px;
  overflow: hidden;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

.title-type {
  border: 1px solid #cccccc;
  //border-radius: 20px;
  color: #cccccc;
  padding: 0px 24px;
  //min-width: 200px;
  height: 30px;
  float: left;
  margin-left: 20px;
  margin-bottom: 25px;
  cursor: pointer;
  position: relative;

  .categories {
    font-size: 18px;
    //word-wrap: break-word;
    //width: 200px;
    white-space: nowrap;
    text-align: center;
    max-height: 80px;
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    overflow: hidden;
    //text-overflow: ellipsis;
    //display: -webkit-box;
    //-webkit-line-clamp: 1;
    //-webkit-box-orient: vertical;
  }
}

:deep(.ant-checkbox + span) {
  display: block;
}

:deep(.ant-checkbox-wrapper-checked) {
  border-color: #3a99f2;
  color: #2a71b2;
}

:deep(.ant-checkbox) {
  display: none;
}
.flex-box{
  display: flex;
  .icon-btn {
    display: flex;
    width: 24px;
    height: 24px;
    :deep(svg) {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
