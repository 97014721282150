<template>
  <div v-if="!isSelf">
    <div class="div-img" v-if="imageUrl">
      <a-avatar :src="imageUrl" :size="80"></a-avatar>
    </div>
    <div class="div-img" v-else>
      <img src="@/assets/menu/noImage.svg" />
    </div>
  </div>
  <a-upload
    v-else
    name="avatar"
    :accept="accept"
    :customRequest="customRequest"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :before-upload="beforeUpload"
    v-auth="['system:picture:upload']"
  >
    <div class="div-img" v-if="imageUrl">
      <a-avatar :src="imageUrl" :size="100"></a-avatar>
    </div>
    <div class="div-img" v-else>
      <img src="@/assets/menu/noImage.svg" />
    </div>
    <div class="plus-upload">
      <i class="upload-img">+</i>
    </div>
    <!--    <div v-else class="div-upload" @mouseleave.stop="leave(true)">-->
    <!--      <div>-->
    <!--        <plus-outlined></plus-outlined>-->
    <!--                <div class="ant-upload-text">Upload</div>-->
    <!--      </div>-->
    <!--    </div>-->
  </a-upload>
</template>
<script>
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    isSelf: Boolean,
    userId: String,
  },
  setup(props) {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const showIMg = ref();
    const imageUrl = ref();
    const accept = ref(`
        .jpg, .JPG, .jpeg, .JPEG, .png, .PNG, .gif, .GIF
      `);
    // 上传前文件格式
    const beforeUpload = ({ name }) => {
      const fileType = name.substring(name.lastIndexOf(".") + 1, name.length);
      if (
        !["jpg", "JPG", "jpeg", "JPEG", "png", "PNG", "gif", "GIF"].includes(
          fileType
        )
      ) {
        message.error("只能上传jpg、jpeg、png、gif格式的文件");
        return false;
      }
    };
    const getPhotoUrl = () => {
      if (props.isSelf) {
        $api.queryMyPhoto().then((res) => {
          if (res.data) {
            imageUrl.value = res.data;
            showIMg.value = true;
            store.commit("SET_PORTIAIT", res.data);
          }
        });
        return;
      }
      $api.getPhoto(props.userId).then((res) => {
        imageUrl.value = res.data;
      });
    };
    const setSortHover = (data) => {
      showIMg.value = data;
    };
    const leave = (data) => {
      showIMg.value = data;
    };
    onMounted(() => {
      getPhotoUrl();
    });
    const customRequest = (data) => {
      const formData = new FormData();
      formData.append("file", data.file);
      $api
        .getUploadPhoto(formData)
        .then(() => {
          getPhotoUrl();
          message.success(`file uploaded successfully.`);
        })
        .catch((err) => {
          console.log(err);
          message.error(`file upload failed.`);
        });
    };
    return {
      showIMg,
      beforeUpload,
      customRequest,
      accept,
      imageUrl,
      setSortHover,
      leave,
      getPhotoUrl,
    };
  },
});
</script>
<style lang="less" scoped>
.upload-img {
  line-height: 30px;
  font-size: 16px;
  color: #fff;
}

.avatar-uploader {
  position: relative;
}

.plus-upload {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: #3182ce;
  width: 30px;
  height: 30px;
}

.anticon.anticon-plus-circle {
  color: white;
}

.ant-upload-text {
  margin-top: 8px;
  color: #666;
}

::v-deep(.ant-upload.ant-upload-select-picture-card) {
  border-radius: 50%;
  overflow: hidden;
  border: none;
  width: 100%;
  height: 100%;
  margin-bottom: 8px;
  background: transparent;

  .ant-upload {
    padding: 0;
  }
}

.div-img,
.div-upload {
  width: 100%;
  height: 100%;
}

.div-img {
  img {
    width: 100%;
    height: 100%;
  }
  .ant-avatar {
    width: 100px !important;
    height: 100px !important;
  }
}

.div-upload {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
