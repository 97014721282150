<template>
  <div id="ctf" ref="certificationRef">
    <div class="update-button" v-if="isSelf">
      <a-space>
        <a-button class="edit-button blue-btn" @click="newHandle" v-auth="['system:userInfo:certificatio:add']">
          新增
        </a-button>
      </a-space>
    </div>
    <div class="red-text" v-if="isSelf">
      如果您目前没有对应的证书，请选择【None】。如果您的证书不在目前的证书清单里，请和我们联系添加。
    </div>
    <div class="table">
      <a-table
        :data-source="dataSource"
        size="small"
        :columns="columns"
        :rowKey="(record) => record.id"
        class="gray-table"
        :rowClassName="
          (record, index) => (index / 1 === index ? 'table-striped' : null)
        "
        :pagination="false"
        @change="handleTableChange"
        :scroll="{ x: 800 }"
      >
        <template #operation="{ record }">
<!--          <a-space>
            &lt;!&ndash;            <FormOutlined @click="editHandle(record)" />&ndash;&gt;
            &lt;!&ndash;            <a-popconfirm&ndash;&gt;
            &lt;!&ndash;              v-if="dataSource.length"&ndash;&gt;
            &lt;!&ndash;              title="Sure to delete?"&ndash;&gt;
            &lt;!&ndash;              @confirm="openDelModal(record)"&ndash;&gt;
            &lt;!&ndash;            >&ndash;&gt;
            &lt;!&ndash;              <DeleteOutlined />&ndash;&gt;
            &lt;!&ndash;            </a-popconfirm>&ndash;&gt;
            <span class="operationLink underline" @click="editHandle(record)" v-auth="['system:userInfo:certificatio:update']">更新</span>
            <span class="redLink underline" @click="openDelModal(record)" v-auth="['system:userInfo:certificatio:delete']">删除</span>
          </a-space>-->
          <ActionBtn v-bind="record" :btnList="btnList" @UploadOutlined="editHandle(record)" @DeleteOutlined="openDelModal(record)" />
        </template>
      </a-table>
    </div>
    <!-- 新增修改技能证书弹窗-->
    <div class="staffing-modal" ref="staffingModal" v-if="isSelf">
      <a-modal
        class="modal"
        :getContainer="()=>$refs.certificationRef"
        :title="!isEdited ? '证书新增' : '证书更新'"
        v-model:visible="visible"
        :confirm-loading="confirmLoading"
        :width="'40.6vw'"
        centered
      >
        <template #footer>
          <a-button @click="handleCancel">取消</a-button>
          <a-button type="primary" @click="handleOk">确定</a-button>
        </template>
        <a-form
          class="form"
          ref="formRef"
          :rules="rules"
          :model="ctfForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-item ref="name" name="ctfId" label="名称" class="ctfNameL">
            <a-select
              ref="selectRef"
              class="ctfName"
              v-model:value="ctfForm.ctfId"
              show-search
              :filterOption="filterOption"
              placeholder="选择证书"
              :show-arrow="false"
              @change="ctfChange"
            >
              <a-select-option
                v-for="item in options"
                :value="item.ctfId"
                :key="item.ctfId"
              >
                {{ item.certificate }}
                <!--                <a-tooltip placement="top">-->
                <!--                  <template #title>-->
                <!--                    <span>-->
                <!--                      {{ item.certificate }}-->
                <!--                    </span>-->
                <!--                  </template>-->
                <!--                  {{ item.certificate }}-->
                <!--                </a-tooltip>-->
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            ref="grantDate"
            name="grantDate"
            label="时间"
            class="grantDateL"
          >
            <a-space direction="vertical">
              <a-date-picker
                class="grantDate"
                placeholder="请输入具体时间"
                v-model:value="ctfForm.grantDate"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                :disabled-date="disabledDate"
              />
            </a-space>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
    <div v-if="isSelf">
<!--      <a-modal
          v-model:visible="delCtfModalVisible"
          width="20.8vw"
          centered
      >
        <template #footer>
          <a-button @click="onCancel">取消</a-button>
          <a-button type="primary" @click="onDelete">确定</a-button>
        </template>
        <div class="delText"></div>
      </a-modal>-->
      <ModalBox v-model="delCtfModalVisible" :ok="onDelete" :content="'是否确定删除此证书？'" />
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";

import { message } from "ant-design-vue";

import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import moment from "moment";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { computed } from "@vue/runtime-core";
import ActionBtn from '@/components/ActionBtn'
import ModalBox from '@/components/ModalBox'

const btnList = [
  {
    name: '上传',
    icon: 'UploadOutlined',
    auth: ['system:userInfo:certificatio:update']
  },
  {
    name: '删除',
    icon: 'DeleteOutlined',
    auth: ['system:userInfo:certificatio:delete']
  },
]
export default defineComponent({
  components: {ActionBtn,ModalBox},
  props: {
    isSelf: Boolean,
    userId: String,
  },
  setup(props) {
    const { $api } = useGlobalPropertyHook();

    const columns = computed(() => {
      if (props.isSelf) {
        return [
          {
            title: "证书名称",
            dataIndex: "certificateName",
            ellipsis: true,
            slots: {
              customRender: "certificateName",
            },
          },
          {
            title: "获得时间",
            dataIndex: "grantDate",
            slots: {
              customRender: "grantDate",
            },
            width: 200,
          },
          {
            title: "操作",
            dataIndex: "operation",
            width: 100,
            slots: {
              customRender: "operation",
            },
            fixed: "right",
          },
        ];
      }
      return [
        {
          title: "证书名称",
          dataIndex: "certificateName",
          ellipsis: true,
          slots: {
            customRender: "certificateName",
          },
        },
        {
          title: "获得时间",
          dataIndex: "grantDate",
          slots: {
            customRender: "grantDate",
          },
          width: 200,
        },
      ];
    });

    const selectRef = ref();
    const options = ref([]);
    const delCtfModalVisible = ref(false);
    const filterOption = (input, option) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };

    const visible = ref(false);
    const confirmLoading = ref(false);
    const isEdited = ref(true);
    const showModal = () => {
      visible.value = true;
    };
    const editHandle = (record) => {
      ctfForm.value.ctfId = record.ctfId;
      ctfForm.value.grantDate = record.grantDate;
      ctfForm.value.id = record.id;
      isEdited.value = true;
      showModal();
      addCtfNameCache(record.certificateName);
    };

    const newHandle = () => {
      isEdited.value = false;
      ctfForm.value.ctfId = "";
      ctfForm.value.grantDate = "";
      showModal();
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(() => {
          onSubmit();
        })
        .catch((error) => {
          message.error("证书名和获取日期不能为空");
          console.log("error", error);
        });
    };

    const handleCancel = () => {
      visible.value = false;
    };

    const formRef = ref();

    const store = useStore();

    const pagination = reactive({
      pageSize: 100,
      total: 0,
      current: 1,
      showSizeChanger: false,
      showQuickJumper: true,
      showLessItems: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
    });

    const handleTableChange = (paginations) => {
      pagination.current = paginations.current;
      getdata();
    };

    const disabledDate = (current) => {
      // Can not select days after today and today
      return current && current > moment().endOf("day");
    };

    const rules = {
      ctfId: [
        {
          required: true,
          message: "请输入证书名称",
          trigger: "blur",
        },
      ],
      grantDate: [
        {
          required: true,
          message: "请输入获取时间",
          trigger: "blur",
        },
      ],
    };

    const onSubmit = () => {
      confirmLoading.value = true;
      formRef.value.validate().then(() => {
        const selectCtf = options.value.find(
          (item) => item.ctfId === ctfForm.value.ctfId
        );
        const ctfName = selectCtf ? selectCtf.certificate : '';
        const params = {
          userId: store.state.app.user.id,
          ctfId: ctfForm.value.ctfId,
          name: ctfName,
          id: ctfForm.value.id,
        };

        $api
          .isExisted(params)
          .then(async (res) => {
            if (res.data.body == 0) {
              handleFormAddEdit(isEdited.value);
            } else {
              if (isCurrentCtfName(ctfName)) {
                visible.value = false;
                resetCtfName();
              } else {
                message.error("此证书已存在！");
              }
              confirmLoading.value = false;
            }
          })
          .catch(() => {
            confirmLoading.value = false;
            message.error("保存失败！");
          });
      });
    };
    // 添加证书名称缓存
    let ctfNameCache = undefined;
    const addCtfNameCache = (val) => {
      ctfNameCache = val;
    };
    //验证证书名称缓存
    const isCurrentCtfName = (currentVal) => {
      return currentVal === ctfNameCache;
    };
    //重置证书名称缓存
    const resetCtfName = () => {
      ctfNameCache = undefined;
    };
    const handleFormAddEdit = async (isEdit) => {
      const selectCtf = options.value.find(
        (item) => item.ctfId === ctfForm.value.ctfId
      );
      const ctfName = selectCtf.certificate;
      const addParams = {
        userId: store.state.app.user.id,
        ctfId: ctfForm.value.ctfId,
        certificateName: ctfName,
        grantDate: ctfForm.value.grantDate,
        id: ctfForm.value.id,
      };
      const editParams = {
        userId: store.state.app.user.id,
        ctfId: ctfForm.value.ctfId,
        name: ctfName,
        grantDate: ctfForm.value.grantDate,
        id: ctfForm.value.id,
      };

      const res = isEdit
        ? await $api.updateCertification(editParams)
        : await $api.addCertification(addParams);
      if (res.code == 200) {
        const msg = isEdited.value ? "编辑成功！" : "新建成功";
        message.success(msg);
        resetForm();
        getdata();
        visible.value = false;
        resetCtfName();
        confirmLoading.value = false;
      }
    };

    let state = reactive({
      dataSource: [],
    });
    let ctfList = {};
    const openDelModal = (key) => {
      delCtfModalVisible.value = true;
      Object.assign(ctfList, key);
    };

    const onDelete = () => {
      $api.deleteCertification(ctfList.id).then(() => {
        successDelete();
        // resetForm();
        getdataReset();
        delCtfModalVisible.value = false;
      });
    };

    const onCancel = () => {
      delCtfModalVisible.value = false;
    };

    let getdata = () => {
      const data = {
        userId: props.userId,
        pageSize: pagination.pageSize,
        pageIndex: pagination.current,
      };
      $api.getAllByUserCode(data).then((res) => {
        pagination.total = res.data.total;
        state.dataSource = res.data.resource;
      });
    };

    let getdataReset = () => {
      const data = {
        userId: store.state.app.user.id,
        pageSize: pagination.pageSize,
        pageIndex: 1,
        current: 1,
      };
      $api.getAllByUserCode(data).then((res) => {
        pagination.total = res.data.total;
        state.dataSource = res.data.resource;
      });
    };

    onMounted(() => {
      getCtfOptions();
      getdata();
    });

    const successDelete = () => {
      message.success("删除成功", 2);
    };

    const resetForm = () => {
      formRef.value.resetFields();
    };

    //证书新增修改弹窗逻辑v
    const ctfForm = ref({
      id: "",
      userId: store.state.app.user.id,
      ctfId: "",
      name: "",
      grantDate: "",
    });

    const getCtfOptions = () => {
      $api.getAllCtf().then((res) => {
        options.value = res.data.body;
      });
    };

    const ctfChange = () => {
      // None
      if (ctfForm.value.ctfId === "0") {
        ctfForm.value.grantDate = moment().format("YYYY-MM-DD");
      }
    };

    return {
      isEdited,
      onCancel,
      openDelModal,
      delCtfModalVisible,
      handleCancel,
      filterOption,
      options,
      newHandle,
      ctfForm,
      visible,
      confirmLoading,
      handleOk,
      handleTableChange,
      pagination,
      formRef,
      rules,
      successDelete,
      columns,
      ...toRefs(state),
      disabledDate,
      editHandle,
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 18,
      },
      selectRef,
      ctfChange,
      onDelete,
      btnList
    };
  },
});
</script>
<style lang="less" scoped>
@import "~@/style/aModal.less";
.delText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
}

.ant-space-item {
  > a {
    text-decoration: underline;
  }
}

:deep(.ant-calendar-picker-icon) {
  color: #3182ce !important;
}

.ant-modal-header {
  height: 80px;
  line-height: 80px;
}

#ctf {
  margin-top: -30px;
  padding-top: 60px;
  position: relative;
  margin-left: 15px;

  .update-button {
    position: absolute;
    right: 0;
    top: 0;

    .edit-button {
      width: 90px;
    }
  }

  .red-text {
    color: #ff4d4f;
    margin-bottom: 10px;
  }

  .table {
    .edit {
      width: 20px;
      cursor: pointer;
    }
  }
}
</style>
